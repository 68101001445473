<template>
  <div>
    <div class="flex flex-col items-stretch items-start justify-center">
      <v-timeline align="start" side="end">
        <v-timeline-item
          v-for="moneyMovement in moneyMovementsByDate"
          :key="moneyMovement[0]"
          dot-color="cyan"
          size="small"
        >
          <div class="pt-1 headline font-weight-bold text-text_color">
            {{ t(moneyMovement[0]) }}
          </div>
          <div>
            <div
              v-for="movement in moneyMovement[1]"
              :key="`store-grid-item-${movement?.id}`"
              class="h-auto mb-3"
            >
              <MyAccountMoneyMovementsCard
                class="transition-all duration-300 transform hover:scale-105"
                :money-movement="movement"
              />
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>
    <ClientOnly>
      <MevcutInfiniteScroll
        :has-initial-cached-materials="moneyMovements.length > 0"
        class="my-5"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { MoneyMovement } from '~~/composables/useMenuModel'
import useMenuModel from '~~/composables/useMenuModel'

const props = defineProps({
  itemsPerPage: { type: Number, default: 10 }
})
const route = useRoute()
const { groupBy } = useMenuModel()

const moneyMovementsByDate = ref<Map<string, MoneyMovement[]>>(
  {} as Map<string, MoneyMovement[]>
)
const moneyMovements = ref<MoneyMovement[]>([])

const toast = useToast()
const { t } = useI18n()
const { moneyMovementModel } = useMenuModel()
const { infiniteId, scrollFetch, loadMore } = useInfiniteScroll(
  moneyMovements.value.length,
  props.itemsPerPage,
  fetchMoneyMovements,
  appendMoneyMovements,
  clearStores
)
function appendMoneyMovements(data: []) {
  moneyMovements.value = [...moneyMovements.value, ...data]
  moneyMovementsByDate.value = groupBy(moneyMovements.value, (obj) => obj.date)
}

if (!moneyMovements.value.length) {
  await scrollFetch()
}

async function fetchMoneyMovements(state: ScrollState) {
  try {
    const queryFilter = {} as any

    if (route.query.month && route.query.year) {
      const yearNumber = tryParseInt(route.query.year?.toString())
      const monthNumber = tryParseInt(route.query.month?.toString())
      if (yearNumber != null && monthNumber != null) {
        queryFilter.from = new Date(
          yearNumber,
          monthNumber - 1,
          1
        ).toISOString()
        queryFilter.to = new Date(yearNumber, monthNumber - 1, 30).toISOString()
      }
    }

    const { data, error } = await useBasicFetch<ApiResponse<[]>>(
      getAllRoutes().moneyMovement.getMoneyMovementsByServer,
      {
        query: {
          ...queryFilter,
          offset: `${state.offset}`,
          limit: `${state.limit}`
        }
      }
    )

    if (error?.value) {
      throw error.value
    }
    if (data.value?.data?.length) {
      data.value!.data.map(moneyMovementModel)
    }
    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('money_movements') }), {
        timeout: 1000
      })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('money_movements') })
      } as ApiResponse<[]>
    }
  }
}
function tryParseInt(input?: string): number | null {
  const parsed = parseInt(input ?? '', 10)
  return isNaN(parsed) ? null : parsed
}
function clearStores() {
  moneyMovements.value = []
}
</script>
